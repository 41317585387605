// TYPES //

export enum RoutePath {
  Root = '/',
  InApp = '/in-app',
  Signin = '/signin',
  Signout = '/signout',
  AccountName = '/account/name',

  StoryStandalone = '/stories/:storyId',
  StoryHighlightReel = '/stories/:storyId/highlight-reel',
  Story = '/projects/:projectId/stories/:storyId',
  Stories = '/projects/:projectId/stories',

  Questions = '/projects/:projectId/questions',

  ProjectSettings = '/projects/:projectId/settings',

  // Redirects
  StoriesLegacyRedirect = '/stories',
  QuestionsLegacyRedirect = '/questions',
  ProjectSettingsLegacyRedirect = '/project-settings',
  ProjectStoriesRedirect = '/projects/stories',
  ProjectQuestionsRedirect = '/projects/questions',
  ProjectSettingsRedirect = '/projects/settings',

  AccountSettings = '/account-settings',

  // Recording
  RecordingIntro = '/projects/:projectId/prompt/:promptId/record/intro',
  RecordingTypeSelection = '/projects/:projectId/prompt/:promptId/record/select-type',
  Recording = '/projects/:projectId/prompt/:promptId/record',
  RecordingPromptRecorded = '/projects/:projectId/prompt/:promptId/record/prompt-recorded',
  RecordingRecovery = '/recording-recovery',
  RecordingFinish = '/projects/:projectId/record/finish',
  ProjectRecord = '/projects/:projectId/record',
  ProjectRecordCustomize = '/projects/:projectId/record/customize',

  // Checkout routes
  Checkout = '/checkout',
  CheckoutAudience = '/checkout/:project/audience',
  CheckoutBiographyContact = '/checkout/biography/contact',
  CheckoutBiographyDelivery = '/checkout/biography/delivery',
  CheckoutBiographyReview = '/checkout/biography/review',
  CheckoutAutobiographyContact = '/checkout/autobiography/contact',
  CheckoutAutobiographyReview = '/checkout/autobiography/review',
  CheckoutBaby = '/checkout/baby',
  CheckoutBabyAudience = '/checkout/baby/audience',
  CheckoutBabyHost = '/checkout/baby/host',
  CheckoutBabyProject = '/checkout/baby/project',
  CheckoutBabyReview = '/checkout/baby/review',
  CheckoutFreeDetails = '/checkout/free/details',
  CheckoutFreeReview = '/checkout/free/review',
  CheckoutSurvey = '/checkout/survey',
  CheckoutSuccess = '/checkout/success',
  CheckoutGetStarted = '/checkout/get-started',
  NewProject = '/new-project',

  // Onboarding
  Onboarding = '/onboarding',
  OnboardingSignin = '/onboarding/signin',
  OnboardingIntro = '/onboarding/intro',
  // Setup
  SetupPromptTypeSelection = '/projects/:projectId/setup/prompts/type',
  SetupTextPromptSelection = '/projects/:projectId/setup/prompts/text',
  SetupPromptsReview = '/projects/:projectId/setup/prompts/review',
  SetupPerspective = '/projects/:projectId/setup/perspective',
  SetupCollaborators = '/projects/:projectId/setup/collaborators',

  // Legacy onboarding
  LegacyOnboardingFinish = '/legacy/onboarding/finish',

  // Other
  Welcome = '/welcome',
  // Invite
  Invite = '/invite',
  InviteSignin = '/invite/signin',

  // Order Book
  BookOrderCoverDesign = '/projects/:projectId/books/:bookId/order/cover-design',
  BookOrderStories = '/projects/:projectId/books/:bookId/order/stories',
  BookOrderPreview = '/projects/:projectId/books/:bookId/order/preview',
  BookOrderQuantity = '/projects/:projectId/books/:bookId/order/quantity',
  BookOrderFinalize = '/projects/:projectId/books/:bookId/order/finalize',
  BookOrderComplete = '/projects/:projectId/book-orders/complete',

  BookReorderPreview = '/projects/:projectId/book-orders/:bookOrderId/preview',
  BookReorderQuantity = '/projects/:projectId/book-orders/:bookOrderId/quantity',
  BookReorderFinalize = '/projects/:projectId/book-orders/:bookOrderId/finalize',

  // Poll
  Poll = '/polls/:pollId',

  // Questionnaire
  QuestionnaireRoot = '/questionnaire',
  Questionnaire = '/questionnaire/:name/:state',

  // Debug
  DebugDeleteAccount = '/debug/delete-account',

  // PDF
  BookCover = '/pdf/book/cover',
  BookGutsCover = '/pdf/book/guts-cover',
  BookTableOfContents = '/pdf/book/table-of-contents',
  BookStoryContent = '/pdf/book/story/content',

  // Legacy Routes
  LegacyCheckoutAudience = '/checkout/audience',
  LegacySeriesSettings = '/series-settings/:personId',
  LegacySeriesSettingsRedirect = '/series-settings',
  LegacyStoryStandalone = '/stories/:personId/story/:storyId',
  LegacyStory = '/stories/:personId/story-view/:storyId',
  LegacyQuestions = '/questions/:personId',
  LegacyRecordingIntro = '/series/:projectId/prompt/:promptId/record/intro',
  LegacyRecordingTypeSelection = '/series/:projectId/prompt/:promptId/record/select-type',
  LegacyRecording = '/series/:projectId/prompt/:promptId/record',
  LegacyRecordingPromptRecorded = '/series/:projectId/prompt/:promptId/record/prompt-recorded',

  // Broken email recovery
  BrokenEmailRecovery_Question = '/project/:projectId/questions',
}

export type RouteType = 'default' | 'empty' | 'record' | 'error';

export interface DefaultRouteConfig {
  type: 'default';
}

export type RouteConfig = DefaultRouteConfig;

export interface ActiveRoute {
  path: string;
  route: RoutePath;
  params: Readonly<Record<string, string | undefined>>;
  search: URLSearchParams;
}

// ERRORS //

export class PageRedirect extends Error {
  constructor(public readonly path: string, public readonly params?: URLSearchParams) {
    super(`Redirecting to ${path}`);
  }
}

export class PageNotFoundRedirect extends PageRedirect {
  constructor() {
    super('/not-found');
  }
}

export class PageErrorRedirect extends PageRedirect {
  constructor() {
    super(`/error`);
  }
}

export class SignInPageRedirect extends PageRedirect {
  constructor(public readonly redirectPath: string, public readonly redirectParams: URLSearchParams) {
    const params = new URLSearchParams();
    // Keep supporting the fallback-signin-email to not break old emails
    const defaultEmail = redirectParams.get('default-signin-email') ?? redirectParams.get('fallback-signin-email');
    if (defaultEmail !== null) {
      params.set('default-signin-email', defaultEmail);
    }

    super(`/signin`, params);
  }
}

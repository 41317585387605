import { useMemo } from 'react';
import { AclGroupRole } from '@remento/types/acl';
import { EntityType } from '@remento/types/entity';
import { notNull } from '@remento/utils/array/notNull';
import { useQueries } from '@tanstack/react-query';

import { useCollection, useEntity } from '@/hooks/useQuery';
import { useServices } from '@/Services';
import { areAllQueriesLoaded } from '@/utils/query';

import { useUser } from '../auth/auth.service.hook';

export function useCurrentUserAclGroupMembers() {
  const { aclService } = useServices();
  const currentUser = useUser();
  return useCollection(
    EntityType.ACL_GROUP_MEMBER,
    currentUser?.id ? { userId: 'signed-in-user' } : null,
    async (_, scope) => {
      return aclService.getUserGroupMembers(scope);
    },
  );
}

export function useCurrentUserAclRoles(aclGroupIds: Array<string> | null | undefined): null | AclGroupRole[] {
  const { authorizationService, entityCacheManagerService, aclService } = useServices();
  const user = useUser();

  // Fetch all the ACL members for the current user
  const aclGroupMembersQuery = useCurrentUserAclGroupMembers();
  const allAclGroupMembersQuery = useQueries({
    queries:
      aclGroupMembersQuery.data?.map((id) => {
        return entityCacheManagerService.buildEntityQuery(EntityType.ACL_GROUP_MEMBER, id, (_, scope) => {
          return aclService.getGroupMember(id, scope);
        });
      }) ?? [],
  });

  return useMemo(() => {
    if (areAllQueriesLoaded(allAclGroupMembersQuery) === false || user == null || aclGroupIds == null) {
      return null;
    }
    // Get the role from the token
    const tokenPermissions = authorizationService.getTokenPermissions();
    const tokenRoles = aclGroupIds
      .map((aclGroupId) => {
        return tokenPermissions[aclGroupId];
      })
      .filter(notNull);

    const userRoles = allAclGroupMembersQuery
      .map((q) => q.data)
      .filter(notNull)
      .filter((aclGroupMember) => aclGroupMember.groupRefIds.some((groupRefId) => aclGroupIds.includes(groupRefId)))
      .map((aclGroupMember) => aclGroupMember.role);
    return Array.from(new Set([...tokenRoles, ...userRoles]));
  }, [aclGroupIds, allAclGroupMembersQuery, authorizationService, user]);
}

export function usePrimaryAclGroup(aclGroupIds: Array<string> | null | undefined, entityType: EntityType) {
  const { entityCacheManagerService, aclService } = useServices();
  const aclGroupsQuery = useQueries({
    queries:
      aclGroupIds?.map((aclGroupId) => {
        return entityCacheManagerService.buildEntityQuery(EntityType.ACL_GROUP, aclGroupId, (_, scope) => {
          return aclService.getGroup(aclGroupId, scope);
        });
      }) ?? [],
  });
  return useMemo(() => {
    return (
      aclGroupsQuery
        .map((q) => q.data)
        .filter(notNull)
        .find((aclGroup) => aclGroup.type === entityType) ?? null
    );
  }, [aclGroupsQuery, entityType]);
}

export function useAclGroupInviteesQuery(aclGroupId?: string | null) {
  const { aclService } = useServices();

  return useCollection(EntityType.ACL_GROUP_INVITEE, aclGroupId ? { aclGroupId } : null, async (params, scope) => {
    return aclService.getGroupInvitees(params.aclGroupId, scope);
  });
}

export function useAclGroupInviteeQuery(inviteeId?: string | null) {
  const { aclService } = useServices();

  return useEntity(EntityType.ACL_GROUP_INVITEE, inviteeId, async (id, scope) => {
    return aclService.getGroupInvitee(id, scope);
  });
}

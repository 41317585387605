import { Deferred } from '@remento/utils/promise/Deferred';
import { QueryClient } from '@tanstack/react-query';
import AsyncLock from 'async-lock';
import {
  Auth,
  getAdditionalUserInfo,
  GoogleAuthProvider,
  isSignInWithEmailLink,
  OAuthProvider,
  sendSignInLinkToEmail,
  signInWithCustomToken,
  signInWithEmailLink,
  signInWithPopup,
  User as FirebaseUser,
  UserCredential,
} from 'firebase/auth';
import { v4 as uuid } from 'uuid';

import { logger } from '@/logger';
import { AnalyticsService, AnalyticsUserProps } from '@/services/analytics/analytics.types';
import { AuthProvider, UserAnalyticsService } from '@/services/analytics/user-analytics/user-analytics.types';
import { BrowserDataService } from '@/services/api/browser-data/browser-data.types';
import { UserIdentifier } from '@/services/api/user/user.types';
import { LocalStoreRepository } from '@/services/local/local-store';
import { captureException } from '@/utils/captureException';

import { api } from '../api';

import {
  AppFlowOrigin,
  AuthChangedCallback,
  AuthRepository,
  AuthService,
  AuthState,
  AuthStateTransition,
  AuthStateType,
  SignInResponse,
} from './auth.types';

export class AuthServiceImpl implements AuthService {
  private changeAuthObservers: Set<AuthChangedCallback> = new Set();
  private changeTokenObservers: Set<(token: string | null) => void> = new Set();
  private authChangedLock = new AsyncLock();
  private state: AuthState | null = null;
  private stateRefreshed = false;

  constructor(
    private auth: Auth,
    private analyticsService: AnalyticsService,
    private userAnalyticsService: UserAnalyticsService,
    private browserDataService: BrowserDataService,
    private sessionStorageRepository: LocalStoreRepository,
    private persistentStorageRepository: LocalStoreRepository,
    private authRepository: AuthRepository,
    private queryClient: QueryClient,
  ) {}

  /**
   * Check if the user's ID token has been refreshed less than 10 seconds ago
   * @param user
   */
  private async isUserIdTokenRecent(user: FirebaseUser): Promise<boolean> {
    const currentIDToken = await user.getIdTokenResult();
    if (currentIDToken == null) {
      throw new Error(`The user does not have an ID token`);
    }
    const isRecent = Date.now() - new Date(currentIDToken.issuedAtTime).getTime() < 10000;
    if (!isRecent && this.stateRefreshed) {
      logger.debug('AUTH.TOKEN_REFRESH_PREVENTED', {
        userId: user?.uid,
        tokenIssuedAt: currentIDToken.issuedAtTime,
      });
      return true;
    }
    return isRecent;
  }

  /**
   * Handle persisting the user's auth state and triggering listeners if necessary. If it detects that the app
   * is getting bootstrapped, it will re-validate the user's ID token
   *
   * @return true if it is confident that the user's state is valid
   */
  private async onFirebaseAuthStateChange(user: FirebaseUser | null): Promise<boolean> {
    // Check if the user's ID token is valid
    const authState = user === null ? AuthStateType.SignedOut : AuthStateType.SignedIn;
    // The auth state is in the persistent storage. Keep in mind that it may be undefined for first time users or
    // users that have not been migrated yet.
    const persistentAuthState = this.authRepository.getPersistedState();
    // If the key doesn't exist in the repository, it means the user is either new (the app is bootstrapping) or
    // hasn't been migrated yet. In that scenario, we trust the firebase state.
    const previousAuthState = persistentAuthState ?? authState;

    logger.debug('AUTH.STATE_CHANGE', {
      userId: user?.uid,
      authState: authState,
      authStatePersisted: persistentAuthState,
    });

    if (user !== null && previousAuthState === authState) {
      // Firebase considers that the user is signed-in. Additionally, the auth state is unchanged which can only
      // happen when the app is bootstrapping (i.e. the auth service is being initialized)
      if (!(await this.isUserIdTokenRecent(user))) {
        logger.debug('AUTH.TOKEN_REFRESH', {
          userId: user?.uid,
          authState: authState,
          authStatePersisted: persistentAuthState,
        });
        // Firebase re-used the existing ID token which was unexpired (a Firebase token expires after an hour). For our
        // app, we want to always check the ID token when we're bootstrapping, so we force a refresh.
        await user.getIdToken(true);
        // This is to prevent a weird issue where isUserIdTokenRecent keeps returning false
        this.stateRefreshed = true;
        // The ID token refresh will cause an auth state change, which will run through the rest of the logic on this
        // function with confidence that the user's auth state is up-to-date.
        return false;
      }
    }

    // Persist the auth state
    if (persistentAuthState == null || previousAuthState !== authState) {
      this.authRepository.persistState(authState);
    }
    return true;
  }

  /**
   * Add a firebase auth listener responsible for dispatching user change events within the auth service. This
   * method resolves to the firebase user
   * @private
   */
  private async initializeFirebaseAuth(): Promise<FirebaseUser | null> {
    const deferred = new Deferred<FirebaseUser | null>();
    this.auth.onIdTokenChanged(async (user) => {
      logger.debug('AUTH.TOKEN_CHANGE', {
        userId: user?.uid,
        userEmail: user?.email,
        userName: user?.displayName,
      });
      const token = await user?.getIdToken();
      this.changeTokenObservers.forEach((observer) => observer(token ?? null));
      logger.debug('AUTH.TOKEN_CHANGE.EMITTED', {
        userId: user?.uid,
      });
      // Lock because the onAuthStateChanged event may fire multiple time while the async logic to handle it is
      // executing
      await this.authChangedLock.acquire('auth-state-changed', async () => {
        try {
          if (await this.onFirebaseAuthStateChange(user)) {
            deferred.resolve(user);
          }
        } catch (e) {
          captureException(e);
          deferred.reject(e as Error);
        }
      });
    });
    return deferred.promise;
  }

  async initialize() {
    // Initialize the firebase auth user and the firebase auth listener
    const authUser = await this.initializeFirebaseAuth();

    // Initialize the session ID
    const sessionId = this.createSessionId();

    if (authUser === null) {
      this.state = {
        type: AuthStateType.SignedOut,
        sessionId,
      };
    } else {
      this.state = {
        type: AuthStateType.SignedIn,
        userId: authUser.uid,
        sessionId,
      };
    }

    // Finish state transitions that were started but didn't finish, likely due to the user exiting in the process
    const pendingTransition = this.authRepository.getPersistedPendingStateTransition();
    switch (pendingTransition) {
      // The sign in transition runs during sign up as well, so we don't need to differentiate
      case AuthStateTransition.SignIn:
        if (authUser != null) {
          // Complete the sign-in transition if a firebase token was successfully acquired
          await this.postSignIn(authUser);
        }
        break;
      case AuthStateTransition.SignOut:
        // Complete the sign-out transition
        await this.signOut();
        break;
      default:
        break;
    }

    this.authRepository.deletePersistentStateTransition();
  }

  /**
   * Responsible for identifying the user with the analytics service and calling the post-sign-in endpoint in the
   * backend which associates the existing session ID to the new user (so that data created anonymously can be linked
   * to the user that signed-in), create/update an Intercom user, and create a user entity in the database.
   * @param user
   * @param provider
   * @param origin
   * @param isNewUser
   * @private
   */
  private async postSignIn(
    user: FirebaseUser,
    provider: AuthProvider | null = null,
    origin: AppFlowOrigin | null = null,
    isNewUser = false,
  ) {
    const token = await this.getAuthToken();
    await api.post(
      '/auth/post-sign-in',
      { sessionId: this.state?.sessionId },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      },
    );

    this.changeAuthObservers.forEach((cb) =>
      cb({
        type: AuthStateType.SignedIn,
        authToken: token ?? '',
        userId: user.uid,
      }),
    );

    if (origin != null && provider != null) {
      await this.userAnalyticsService.onSignIn(provider, isNewUser);
    }
  }

  async sendEmailSignInLink(email: string, backupLocalData?: boolean): Promise<void> {
    // TODO - This method should receive the url to redirect
    const url = new URL(window.location.href);
    url.pathname = '/signin';
    url.searchParams.append('email', email);
    url.searchParams.delete('step');

    if (backupLocalData) {
      const backupId = await this.browserDataService.backupData([
        '@remento/checkout',
        '@remento/redirects',
        '@remento/poll-anonymous-votes',
      ]);
      url.searchParams.set('backup-id', backupId);
    }

    const profile: AnalyticsUserProps = {
      name: null,
      email,
    };

    try {
      await this.analyticsService.identifyEmail(email, profile);
    } catch (error) {
      captureException(error, true);
    }

    await api.post('/auth/sign-in/email', { email, url });
  }

  async signInWithEmail(email: string, emailLink: string, origin: AppFlowOrigin): Promise<SignInResponse> {
    if (!isSignInWithEmailLink(this.auth, emailLink)) {
      throw new Error('Invalid sign in email link: ' + emailLink);
    }

    this.authRepository.persistStateTransition(AuthStateTransition.SignIn);
    const credential = await signInWithEmailLink(this.auth, email, emailLink);
    return this.postSignInWithCredential(credential, 'email', origin);
  }

  async signInWithApple(origin: AppFlowOrigin): Promise<SignInResponse> {
    this.authRepository.persistStateTransition(AuthStateTransition.SignIn);
    const credential = await signInWithPopup(this.auth, new OAuthProvider('apple.com'));
    return this.postSignInWithCredential(credential, 'apple', origin);
  }

  async signInWithGoogle(origin: AppFlowOrigin): Promise<SignInResponse> {
    this.authRepository.persistStateTransition(AuthStateTransition.SignIn);
    const credential = await signInWithPopup(this.auth, new GoogleAuthProvider());
    return this.postSignInWithCredential(credential, 'google', origin);
  }

  async signInWithCustomToken(jwt: string): Promise<SignInResponse> {
    const { data } = await api.post<{ firebaseCustomToken: string }>('/auth/sign-in/token', { jwt });

    this.authRepository.persistStateTransition(AuthStateTransition.SignIn);
    const credential = await signInWithCustomToken(this.auth, data.firebaseCustomToken);
    return this.postSignInWithCredential(credential, 'custom-token', null);
  }

  /**
   * This method is called after a successful sign-in with any of the auth providers. It is responsible for determining
   * if the user signed-in or signed-up and calling the appropriate handler (postSignIn or postSignUp).
   * @param credential
   * @param provider
   * @param origin
   * @private
   */
  private async postSignInWithCredential(
    credential: UserCredential,
    provider: AuthProvider,
    origin: AppFlowOrigin | null,
  ): Promise<SignInResponse> {
    const userInfo = getAdditionalUserInfo(credential);
    if (!userInfo) {
      throw new Error('Failed to retrieve user information');
    }
    await this.postSignIn(credential.user, provider, origin, userInfo.isNewUser);
    this.authRepository.deletePersistentStateTransition();
    return {
      user: {
        fullName: credential.user.displayName,
        email: credential.user.email,
      },
      isNewUser: userInfo.isNewUser,
    };
  }

  async signOut() {
    if (this.state == null) {
      throw new Error('The auth service is not initialized');
    }

    this.authRepository.persistStateTransition(AuthStateTransition.SignOut);
    this.analyticsService.reset();
    this.persistentStorageRepository.clear();
    this.sessionStorageRepository.clear();
    await this.auth.signOut();
    this.queryClient.clear();
    this.state = {
      type: AuthStateType.SignedOut,
      // Generate a new sessionId
      sessionId: this.createSessionId(true),
    };
    this.analyticsService.initialize(this.getUserIdentifier());
    this.authRepository.deletePersistentStateTransition();
    this.userAnalyticsService.onSignOut();
    this.changeAuthObservers.forEach((cb) =>
      cb({
        type: AuthStateType.SignedOut,
      }),
    );
  }

  getUserIdentifier(): UserIdentifier {
    if (this.state === null) {
      throw new Error('The auth service is not initialized');
    }
    if (this.state.type === AuthStateType.SignedIn) {
      return {
        type: 'auth',
        id: this.state.userId,
      };
    }
    return {
      type: 'session',
      id: this.state.sessionId,
    };
  }

  async getAuthToken(): Promise<string | null> {
    return (await this.auth.currentUser?.getIdToken()) ?? null;
  }

  private createSessionId(overwrite = false) {
    let sessionId = this.authRepository.getSessionId();
    if (sessionId === null || overwrite) {
      sessionId = uuid();
      this.authRepository.persistSessionId(sessionId);
    }

    return sessionId;
  }

  getAuthState(): AuthStateType {
    if (this.state == null) {
      throw new Error('The auth service is not initialized');
    }

    return this.state.type;
  }

  onAuthChanged(callback: AuthChangedCallback): () => void {
    this.changeAuthObservers.add(callback);
    return () => this.changeAuthObservers.delete(callback);
  }

  onTokenChanged(callback: (token: string | null) => void): () => void {
    this.changeTokenObservers.add(callback);
    return () => this.changeTokenObservers.delete(callback);
  }
}

import { ReactNode } from 'react';

import { RMText } from '../RMText/RMText';

import { Header, HiddenFiller, Message, Title, Top } from './RMDialogHeader.styles';

interface RMDialogHeaderProps {
  title?: ReactNode;
  message?: string;
  disabled?: boolean;
  leftAdornment?: ReactNode;
  rightAdornment?: ReactNode;
  divider?: boolean;
  centerTitle?: boolean;
  className?: string;
}

export function RMDialogHeader({
  title,
  message,
  leftAdornment,
  rightAdornment,
  divider,
  centerTitle,
  className,
}: RMDialogHeaderProps) {
  return (
    <Header className={className}>
      <Top
        style={{
          '--justify-content': !leftAdornment && !rightAdornment && centerTitle ? 'center' : 'space-between',
        }}
      >
        {leftAdornment ? (
          <div className="left">{leftAdornment}</div>
        ) : (
          centerTitle && rightAdornment && <HiddenFiller>{rightAdornment}</HiddenFiller>
        )}

        <Title
          truncate={divider}
          align={centerTitle ? 'center' : 'left'}
          data-padding-left={!leftAdornment && !centerTitle}
          type="sans"
          size="m"
          bold
          color="on-surface-primary"
        >
          {title}
        </Title>

        {rightAdornment ? (
          <div className="right">{rightAdornment}</div>
        ) : (
          centerTitle && leftAdornment && <HiddenFiller>{leftAdornment}</HiddenFiller>
        )}
      </Top>
      {message && (
        <Message>
          <RMText type="sans" size="xs" color="on-surface-primary">
            {message}
          </RMText>
        </Message>
      )}
    </Header>
  );
}

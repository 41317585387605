import { UserIdentifier } from '@/services/api/user/user.types';

import { AnalyticsPropertyKey } from './analytics-property/analytics-property.types';

export type Page =
  | 'upcoming-prompts'
  | 'stories'
  | 'project-settings'
  | 'my-account-settings'
  | 'prompt-bank'
  | 'prompt-bank-review'
  | 'prompt-upload-photo'
  | 'welcome-video'
  | 'in-app'
  | 'sign-in'
  | 'sign-out'
  | 'account-name-setup'
  // Story
  | 'story'
  | 'story-standalone'
  | 'story-highlight-reel-standalone'
  // Recording
  | 'recording-intro'
  | 'recording-type-selection'
  | 'recording'
  | 'recording-prompt-already-recorded'
  | 'recording-recovery'
  | 'recording-finish'
  | 'project-record'
  | 'project-record-customize'
  // Checkout
  | 'checkout'
  | 'checkout-audience'
  | 'checkout-biography-contact'
  | 'checkout-biography-delivery'
  | 'checkout-biography-review'
  | 'checkout-autobiography-contact'
  | 'checkout-autobiography-review'
  | 'checkout-baby'
  | 'checkout-baby-audience'
  | 'checkout-baby-host'
  | 'checkout-baby-project'
  | 'checkout-baby-review'
  | 'checkout-free-details'
  | 'checkout-free-review'
  | 'checkout-survey'
  | 'checkout-success'
  | 'checkout-get-started'
  | 'new-project'
  // Onboarding
  | 'onboarding'
  | 'onboarding-sign-in'
  | 'onboarding-intro'
  // Setup
  | 'setup-prompt-type-selection'
  | 'setup-text-prompt-selection'
  | 'setup-prompts-review'
  | 'setup-perspective'
  | 'setup-collaborators'
  // Legacy onboarding
  | 'legacy-onboarding-finish'
  // Invite0
  | 'invite'
  | 'invite-sign-in'
  // Book order
  | 'book-order-cover-design'
  | 'book-order-stories'
  | 'book-order-preview'
  | 'book-order-quantity'
  | 'book-order-finalize'
  | 'book-order-complete'
  | 'book-reorder-preview'
  | 'book-reorder-quantity'
  | 'book-reorder-finalize'
  // Poll
  | 'poll'
  // Questionnaire
  | 'questionnaire-root'
  | 'questionnaire'
  // Debug
  | 'debug-delete-account'
  // Legacy
  | 'legacy-checkout-audience'
  | 'legacy-series-settings'
  | 'legacy-series-settings-redirect'
  | 'legacy-story-standalone'
  | 'legacy-story'
  | 'legacy-questions'
  | 'legacy-recording-intro'
  | 'legacy-recording-type-selection'
  | 'legacy-recording'
  | 'legacy-recording-prompt-already-recorded'
  // Broken email recovery
  | 'broken-questions-email-recovery';

export enum Events {
  // GENERIC
  Error = 'error',
  PermissionMicrophoneShown = 'permission.shown.microphone',
  PermissionMicrophoneGranted = 'permission.granted.microphone',
  PermissionMicrophoneDenied = 'permission.denied.microphone',
  PermissionCameraShown = 'permission.shown.camera',
  PermissionCameraGranted = 'permission.granted.camera',
  PermissionCameraDenied = 'permission.denied.camera',
  ActionSwapProject = 'action.swap-project',
  ActionStories = 'action.stories',
  ActionUpcomingPrompts = 'action.upcoming-prompts',
  ActionBookSettings = 'action.book-settings',
  ActionViewBook = 'action.view-book',
  ActionMyAccount = 'action.my-account',
  ActionOrderBooks = 'action.order-books',
  ActionGiftRemento = 'action.gift-remento',
  ActionReferRemento = 'action.refer-remento',

  // UPCOMING PROMPTS
  UpcomingPromptsArrive = 'upcoming-prompts.arrive',
  UpcomingPromptsNewPrompt = 'upcoming-prompts.new-prompt',
  UpcomingPromptsReorder = 'upcoming-prompts.reorder',
  UpcomingPromptsAction = 'upcoming-prompts.action',

  // PROMPT BANK
  PromptBankArrive = 'prompt-bank.arrive',
  PromptBankQuestionSelected = 'prompt-bank.question-selected',
  PromptBankPromptAdded = 'prompt-bank.prompt-added',
  PromptBankQuestionAdded = 'prompt-bank.question-added',
  PromptBankPromptsAdded = 'prompt-bank.prompts-added',
  PromptBankPromptEdited = 'prompt-bank.prompt-edited',
  PromptBankAction = 'prompt-bank.action',
  PromptBankPromptAddedCustom = 'prompt-bank.prompt-added-custom',

  // PROMPT
  PromptArrive = 'prompt.arrive',
  PromptAction = 'prompt.action',
  PromptPhotoBrowse = 'prompt.photo.browse',
  PromptPhotoSelect = 'prompt.photo.select',

  // STORYTELLING
  StorytellingArrive = 'storytelling.arrive',
  StorytellingResponseStart = 'storytelling.response.start',
  StorytellingLearnMore = 'storytelling.learn-more',
  StorytellingInputsSelect = 'storytelling.inputs.select',
  StorytellingInputsDecline = 'storytelling.inputs.decline',
  StorytellingInputsBrowse = 'storytelling.inputs.browse',
  StorytellingInputsChange = 'storytelling.inputs.change',
  StorytellingRecordingStart = 'storytelling.recording.start',
  StorytellingRecordingNextPrompt = 'storytelling.recording.next-prompt',
  StorytellingRecordingRestart = 'storytelling.recording.restart',
  StorytellingRecordingEnd = 'storytelling.recording.end',
  StorytellingRecordingFinish = 'storytelling.recording.finish',
  StorytellingRecordingSuccess = 'storytelling.recording.success',
  StorytellingRecordingInterruptError = 'storytelling.recording.interrupt-error',
  StorytellingRecordingNoDataError = 'storytelling.recording.no-data-error',
  StorytellingRecordingQueueDisconnectError = 'storytelling.recording.queue-disconnect-error',
  StorytellingAction = 'storytelling.action',
  RecordingSettingsNotificationChanged = 'recording.settings.notification-changed',

  // VIEWER
  StoryViewerArrive = 'view-story.arrive',
  StoryViewerWatch = 'view-story.watch',
  StoryViewerShare = 'view-story.share',
  StoryViewerMute = 'view-story.mute',
  StoryViewerLike = 'view-story.like',
  StoryViewerDownload = 'view-story.download',
  StoryViewerSummaryView = 'view-story.summary.view',
  StoryViewerSummaryEdit = 'view-story.summary.edit',
  StoryViewerSummaryAction = 'view-story.summary.action',
  StoriesViewerArrive = 'view-stories.arrive',
  StoriesViewerOpen = 'view-stories.open',
  StoriesViewerAction = 'view-stories.action',
  ReactionArrive = 'reaction.arrive',
  ReactionSent = 'reaction.sent',

  // COLLABORATION
  CollaborationInvite = 'collaboration.invite',
  CollaborationInviteViaEmail = 'collaboration.invite.via-email',
  CollaborationInviteViaLink = 'collaboration.invite.via-link',
  CollaborationAcceptInvite = 'collaboration.accept-invite',
  CollaborationManagePermissions = 'collaboration.manage-permissions',
  CollaborationGetHelp = 'collaboration.get-help',
  CollaborationAccessRequested = 'collaboration.access-requested',

  // AUTH
  UserSignIn = 'auth.signin',
  UserSignUp = 'auth.signup',
  UserSignOut = 'auth.signout',

  // CHECKOUT
  CheckoutArrive = 'checkout.arrive',
  CheckoutLeave = 'checkout.leave',
  CheckoutPersonalize = 'checkout.personalize',
  CheckoutContactForm = 'checkout.contact-form',
  CheckoutStorytellerForm = 'checkout.storyteller-form',
  CheckoutStart = 'checkout.start',
  CheckoutSourceForm = 'checkout.source-form',
  CheckoutGetStarted = 'checkout.get-started',
  CheckoutSuccess = 'checkout.success',

  // ONBOARDING
  OnboardingArrive = 'onboarding.arrive',
  OnboardingStart = 'onboarding.start',
  OnboardingSubmitPromptTypeSelect = 'onboarding.submit.prompt-type-select',
  OnboardingSubmitPromptReview = 'onboarding.submit.prompt-review',
  OnboardingComplete = 'onboarding.complete',
  OnboardingEnd = 'onboarding.end',
  UserOnboardingProgress = 'user.onboarding-progress',

  // POLL
  PollCreated = 'poll.created',
  PollDeleted = 'poll.deleted',
  PollQuestionsShuffled = 'poll.questions-shuffled',
  PollVoted = 'poll.voted',

  // QUESTIONNAIRE
  QuestionnaireArrive = 'questionnaire.arrive',
  QuestionnaireQuestionArrive = 'questionnaire.question.arrive',
  QuestionnaireQuestionSelect = 'questionnaire.question.select',
  QuestionnaireQuestionDeselect = 'questionnaire.question.deselect',
  QuestionnaireResultArrive = 'questionnaire.result.arrive',
  QuestionnaireSkip = 'questionnaire.skip',

  // PROJECT
  ProjectDefaultPespectiveChange = 'project.default-perspective.change',
  ProjectDefaultPespectiveDialogViewed = 'project.default-perspective-dialog.viewed',
}

export interface AnalyticsUserProps {
  appBookUserId?: string;
  name?: string | null;
  firstName?: string | null;
  lastName?: string | null;
  email?: string | null;
  phone?: string | null;
  campaign?: string | null;
  currentCheckoutUseCase?: 'babybook' | 'biography' | 'autobiography' | null;
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export type TrackPayload = Record<string, any>;

export interface AnalyticsService {
  initialize(userIdentifier: UserIdentifier): void;
  identify(ruid: string, user: AnalyticsUserProps, temporary?: boolean): Promise<void>;
  identifyEmail(email: string, user: AnalyticsUserProps): Promise<void>;
  reset(): void;

  track(event: Events, payload?: TrackPayload): void;
  page(page: Page, payload?: TrackPayload): void;

  setProperty(key: AnalyticsPropertyKey, value: string): void;
  setProperties(properties: Record<string, string>): void;
  getProperty(key: AnalyticsPropertyKey): string | null;
  getAllProperties(): Record<string, string> | null;
  getRuid(): string | null;
}

export interface AnalyticsRepository {
  initialize(userIdentifier: UserIdentifier): void;
  identify(ruid: string, user: AnalyticsUserProps, overwrite: boolean): void;
  reset(): void;

  track(event: string, payload?: Record<string, unknown>): void;
  page(page: Page, payload?: Record<string, unknown>): void;
}

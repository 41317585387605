import { ComponentProps, useCallback, useEffect, useState } from 'react';
import { Navigate, useParams, useSearchParams } from 'react-router-dom';

import { PageLoader } from '@/components/PageLoader/PageLoader';
import { RementoPage } from '@/modules/routing';
import { useServices } from '@/Services.tsx';

export type LegacyPersonToProjectRedirectProps = Omit<ComponentProps<typeof Navigate>, 'to'> & {
  to: (params: Record<string, string>, searchParams: URLSearchParams) => string | Promise<string>;
  findProjectBy?: 'personId' | 'storyId';
};

function InternalLegacyRedirect({ to, findProjectBy, ...props }: LegacyPersonToProjectRedirectProps) {
  const { projectCacheService } = useServices();
  const [searchParams] = useSearchParams();
  const params = useParams();
  const [url, setUrl] = useState<string>();

  const loadNewUrl = useCallback(async () => {
    const newParams = { ...params };

    if (newParams.personId != null && findProjectBy == 'personId') {
      const projects = await projectCacheService.getProjectsFromRecipientPersonId(newParams.personId);
      if (projects.length === 0) {
        setUrl('/');
        return;
      }

      // We can't know the exact project, so we assume it's always the first one
      newParams.projectId = projects[0];
    } else if (newParams.storyId != null && findProjectBy == 'storyId') {
      const project = await projectCacheService.getProjectFromStoryId(newParams.storyId);
      if (project == null) {
        setUrl('/');
        return;
      }

      newParams.projectId = project.id;
    }

    setUrl(await to(newParams as Record<string, string>, searchParams));
  }, [params, searchParams, projectCacheService, to, findProjectBy]);

  useEffect(() => {
    loadNewUrl();
  }, [loadNewUrl]);

  if (url == null) {
    return <PageLoader />;
  }

  return <Navigate to={`${url}?${searchParams.toString()}`} {...props} />;
}

export function LegacyRedirect(props: LegacyPersonToProjectRedirectProps) {
  return (
    <RementoPage type="empty">
      <InternalLegacyRedirect {...props} />
    </RementoPage>
  );
}

import { faXmark } from '@fortawesome/pro-solid-svg-icons';

import { RMIconButton } from '../RMIconButton/RMIconButton';
import {
  RMIconButtonBackgroundColor,
  RMIconButtonColor,
  RMIconButtonSize,
  RMIconButtonStateColor,
} from '../RMIconButton/RMIconButton.styles';

interface RMCloseButtonProps {
  id?: string;
  color?: RMIconButtonColor;
  size?: RMIconButtonSize;
  backgroundColor?: RMIconButtonBackgroundColor;
  stateColor?: RMIconButtonStateColor;
  onClick?: () => void;
}

export function RMCloseButton({
  id,
  color = 'primary',
  size = 'xl',
  backgroundColor = 'transparent',
  stateColor = 'darken-neutral',
  onClick,
}: RMCloseButtonProps) {
  return (
    <RMIconButton
      id={id}
      color={color}
      icon={faXmark}
      size={size}
      tooltip={null}
      backgroundColor={backgroundColor}
      stateColor={stateColor}
      onClick={onClick}
    />
  );
}

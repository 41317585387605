import 'react-toastify/dist/ReactToastify.css';
import '@vidstack/react/player/styles/base.css';

import { useEffect, useMemo } from 'react';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import { Provider as WrapBalancerProvider } from 'react-wrap-balancer';
import { css } from '@linaria/core';
import { styled } from '@linaria/react';

import { createNavbarStore } from './modules/navbar/states/navbar.state';
import { NavbarStoreProvider } from './modules/navbar/states/navbar.state.context';
import { routes } from './routes';
import { appStyles } from './styles';

const router = createBrowserRouter(routes);

export function App() {
  const navbarStore = useMemo(() => createNavbarStore(), []);

  useEffect(() => {
    console.log('Build commit: ' + import.meta.env.VITE_VERCEL_GIT_COMMIT_SHA);
  }, []);

  return (
    <HelmetProvider>
      <Helmet>
        <style>{appStyles}</style>
      </Helmet>
      <Container>
        <WrapBalancerProvider>
          <NavbarStoreProvider store={navbarStore}>
            <RouterProvider router={router} />
          </NavbarStoreProvider>
          <ToastContainer enableMultiContainer containerId="root-toast" />
          <ToastContainer enableMultiContainer containerId="full-screen-toast" className={fullScreenToastClassName} />
        </WrapBalancerProvider>
      </Container>
    </HelmetProvider>
  );
}

const fullScreenToastClassName = css`
  width: 100%;
  text-align: center;
  padding: 0;

  [data-mobile]:not([data-mobile='true']) & {
    top: 0;
  }

  body[data-mobile='true'] & {
    bottom: 0;
  }
`;

const Container = styled.div`
  height: 100%;
  width: 100%;
`;

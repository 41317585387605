import { MarketingMetadata } from '@remento/types/marketing';

import { AnalyticsService, Events } from '../analytics.types';

import { CheckoutAnalyticsService, CheckoutArriveType } from './checkout-analytics.types';

export class DefaultCheckoutAnalyticsService implements CheckoutAnalyticsService {
  constructor(private analyticsService: AnalyticsService) {}

  onCheckoutArrived(type: CheckoutArriveType): void {
    this.analyticsService.track(Events.CheckoutArrive, { type });
  }

  onCheckoutLeave(page: string): void {
    this.analyticsService.track(Events.CheckoutLeave, {
      page,
    });
  }

  onCheckoutPersonalized(variant: 'someone-else' | 'myself'): void {
    this.analyticsService.track(Events.CheckoutPersonalize, {
      variant,
    });
  }

  onCheckoutContactFormSubmitted(): void {
    this.analyticsService.track(Events.CheckoutContactForm);
  }

  onCheckoutStorytellerFormSubmitted(firstName: string, lastName: string): void {
    this.analyticsService.track(Events.CheckoutStorytellerForm, {
      'storyteller.name.first': firstName,
      'storyteller.name.last': lastName,
    });
  }

  onCheckoutStarted(payload: MarketingMetadata): void {
    this.analyticsService.track(Events.CheckoutStart, payload);
  }

  onCheckoutSourceFormSubmitted(reason: string): void {
    this.analyticsService.track(Events.CheckoutSourceForm, {
      reason,
    });
  }

  onCheckoutGetStarted(): void {
    this.analyticsService.track(Events.CheckoutGetStarted);
  }

  onCheckoutSuccess(invoiceNumber: string, price: number, paid: number): void {
    this.analyticsService.track(Events.CheckoutSuccess, { invoiceNumber, price, paid });
  }
}

import { css } from '@linaria/core';
import { styled } from '@linaria/react';

export const Text = styled.span<{
  color?: string;
  fontFamily: string;
  fontSize: string;
  fontWeight: number;
  letterSpacing: string | undefined;
  underline: boolean;
  align: 'left' | 'right' | 'center' | 'justify';
  lineHeight: string;
}>`
  color: ${(props) => props.color ?? 'unset'};
  font-family: ${(props) => props.fontFamily};
  font-size: ${(props) => props.fontSize};
  font-weight: ${(props) => props.fontWeight};
  letter-spacing: ${(props) => props.letterSpacing ?? 0};
  text-decoration: ${(props) => (props.underline ? 'underline' : 'none')};
  text-align: ${(props) => props.align};
  line-height: ${(props) => props.lineHeight};

  &[data-truncate='true'] {
    flex: 1;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    min-width: 0;
  }
`;

import { BaseEntity } from './base-entity';

export enum AssetAlternativeMetadataType {
  IMAGE = 'image',
  IMAGE_THUMBNAIL = 'image-thumbnail',
  VIDEO = 'video',
  PROMPT_VIDEO = 'prompt-video',
  PDF_DOCUMENT = 'pdf-document',
}

export interface ImageMetadata {
  type: AssetAlternativeMetadataType.IMAGE;
  width: number;
  height: number;
}

export type ThumbnailType = 'image' | 'gif';

export interface ImageThumbnailMetadata {
  type: AssetAlternativeMetadataType.IMAGE_THUMBNAIL;
  alternativeId: string;
  thumbnailFormat: ThumbnailType;
  width: number;
  height: number;
}

export enum ChapterStatus {
  PROCESSING = 'processing',
  PROCESSED = 'processed',
}

export interface VideoMetadataChapter {
  id: string;
  title: string;
  ranges: { start: number; end: number }[];
  index: number;
  status: ChapterStatus;
}

export interface VideoMetadata {
  type: AssetAlternativeMetadataType.VIDEO;
  duration: number;
  chapters: Array<VideoMetadataChapter>;
}

export enum AssetAlternativeStatus {
  ACTIVE = 'active',
}

export enum BaseAssetAlternativeType {
  ORIGINAL = 'original',
}

export enum ImageAssetAlternativeType {
  SMALL = 'small',
  MEDIUM = 'medium',
  LARGE = 'large',
}

export enum RecordingAssetAlternativeType {
  NO_SILENCE = 'no-silence',
  OVERLAYED = 'overlayed',
  HIGHLIGHT_REEL = 'highlight-reel',
}

export enum PdfDocumentAssetAlternativeType {
  PREVIEW = 'preview',
}

export type AssetAlternativeType =
  | BaseAssetAlternativeType
  | ImageAssetAlternativeType
  | RecordingAssetAlternativeType
  | PdfDocumentAssetAlternativeType;

export interface PromptVideoMetadata {
  type: AssetAlternativeMetadataType.PROMPT_VIDEO;
  chapterId: string;
  chapters: VideoMetadataChapter[];
}

export interface PdfDocumentMetadata {
  type: AssetAlternativeMetadataType.PDF_DOCUMENT;
  pageCount: number;
  aclGroupTokenIds?: string[];
}

export type AssetAlternativeMetadata =
  | ImageMetadata
  | ImageThumbnailMetadata
  | VideoMetadata
  | PromptVideoMetadata
  | PdfDocumentMetadata
  | null;

export interface AssetAlternative extends BaseEntity {
  assetId: string;
  type: AssetAlternativeType;
  mimeType: string;
  metadata: AssetAlternativeMetadata;
  status: AssetAlternativeStatus;
}

export interface AssetAlternativeView extends AssetAlternative {
  url: string;
}

import { useMemo } from 'react';
import { BaseAssetAlternativeType, ImageAssetAlternativeType } from '@remento/types/alternative';
import { EntityType } from '@remento/types/entity';
import { Project, Prompt, PromptStatus, PromptType } from '@remento/types/project';
import { Story, StoryStatus } from '@remento/types/story';
import { notNull } from '@remento/utils/array/notNull';
import { useQueries, useQuery } from '@tanstack/react-query';

import { useCollection, useCollectionEntityData, useEntity } from '@/hooks/useQuery';
import { useServices } from '@/Services';
import { areAllQueriesLoaded } from '@/utils/query';

import { usePrimaryAclGroup } from '../acl';
import { useAlternativeFileUrl, useAlternativeQuery, useAlternativeType, useAssetAlternativesQuery } from '../asset';

export function useProjectsQuery() {
  const { projectService } = useServices();
  return useCollection(EntityType.PROJECT, {}, (context) => projectService.getProjects(context));
}

export function useProjectQuery(projectId: string | null | undefined) {
  const { projectService } = useServices();
  return useEntity(EntityType.PROJECT, projectId, (id) => projectService.getProject(id));
}

export function useProjectInviteLink(projectId: string | null) {
  const { aclService } = useServices();

  const projectQuery = useProjectQuery(projectId);
  const projectAclGroup = usePrimaryAclGroup(projectQuery.data?.acl, EntityType.PROJECT);

  return useQuery({
    enabled: projectAclGroup != null,
    queryKey: ['project-invite-link', projectId].filter(notNull),
    queryFn: async () => {
      // The id will never be null here.
      return aclService.generateInviteLink(projectAclGroup?.id ?? '');
    },
  });
}

export function useProjectInitials(project: Project | null | undefined): string {
  return useMemo(() => project?.name?.[0] ?? 'N/A', [project]);
}

export function useProjectCoverUrl(project: Project | null | undefined): string | null {
  const alternativesQuery = useAssetAlternativesQuery(project?.coverAssetId);
  const alternativeQuery = useAlternativeQuery(alternativesQuery.data?.[0] ?? null);

  return useAlternativeFileUrl(alternativeQuery.data?.id);
}

export function useProjectPromptsQuery(projectId: string | null) {
  const { projectService } = useServices();
  return useCollection(EntityType.PROMPT, projectId ? { projectId } : null, (params, scope) =>
    projectService.getProjectPrompts(params.projectId, scope),
  );
}

export function useUnrecordedProjectPrompts(projectId: string | null, status?: PromptStatus) {
  const { projectService, storyService, entityCacheManagerService } = useServices();

  const allPromptsIdsQuery = useProjectPromptsQuery(projectId);
  const allPromptsQueries = useQueries({
    queries:
      allPromptsIdsQuery.data?.map((id) => {
        return entityCacheManagerService.buildEntityQuery(EntityType.PROMPT, id, (_, scope) => {
          return projectService.getPrompt(id, scope);
        });
      }) ?? [],
  });

  const storiesIds = useQueries({
    queries:
      allPromptsIdsQuery.data?.map((promptId) => {
        return entityCacheManagerService.buildCollectionQuery(EntityType.STORY, { promptId }, () =>
          storyService.getPromptStory(promptId),
        );
      }) ?? [],
  });

  const allStoriesQueries = useQueries({
    queries:
      storiesIds
        ?.map((storyIdsQuery) => {
          return storyIdsQuery.data?.map((storyId) =>
            entityCacheManagerService.buildEntityQuery(EntityType.STORY, storyId, (_, scope) => {
              return storyService.getStory(storyId, scope);
            }),
          );
        })
        .flat()
        .filter(notNull) ?? [],
  });

  return useMemo(() => {
    if (areAllQueriesLoaded([allPromptsIdsQuery, ...allPromptsQueries, ...allStoriesQueries]) === false) {
      return null;
    }

    const prompts = allPromptsQueries.map((q) => q.data).filter(notNull);
    const storyByPromptId = new Map<string, Story>();
    for (const prompt of prompts) {
      const story = allStoriesQueries.find((query) => {
        return query.data?.promptId === prompt.id;
      })?.data;

      if (story) {
        storyByPromptId.set(prompt.id, story);
      }
    }

    return prompts
      .filter((prompt) => {
        if (status && prompt.status !== status) {
          return false;
        }
        const story = storyByPromptId.get(prompt.id);
        if (story && (story.recordingsIds.length > 0 || story.status === StoryStatus.PROCESSING)) {
          return false;
        }
        return true;
      })
      .map((p) => p.id);
  }, [allPromptsIdsQuery, allPromptsQueries, allStoriesQueries, status]);
}

export function useSortedPromptIds(ids: string[] | null, field: 'createdAt', order: 'asc' | 'desc') {
  const { projectService } = useServices();
  const prompts = useCollectionEntityData(ids, EntityType.PROMPT, (id) => projectService.getPrompt(id));
  return useMemo(() => {
    return prompts
      .sort((a, b) => {
        switch (field) {
          case 'createdAt': {
            return order === 'asc'
              ? a.audit.create.date - b.audit.create.date
              : b.audit.create.date - a.audit.create.date;
          }
        }
      })
      .map((p) => p.id);
  }, [field, order, prompts]);
}

export function usePromptQuery(promptId: string | null) {
  const { projectService } = useServices();
  return useEntity(EntityType.PROMPT, promptId, (id, scope) => projectService.getPrompt(id, scope));
}

export function usePromptQuestionsQuery(promptId: string | null) {
  const { projectService } = useServices();
  return useCollection(EntityType.QUESTION, promptId ? { promptId } : null, (params, scope) =>
    projectService.getPromptQuestions(params.promptId, scope),
  );
}

export function useQuestionQuery(questionId: string | null) {
  const { projectService } = useServices();
  return useEntity(EntityType.QUESTION, questionId, (id, scope) => projectService.getQuestion(id, scope));
}

export function usePromptFirstQuestionQuery(promptId: string | null) {
  const questions = usePromptQuestionsQuery(promptId);
  return useQuestionQuery(questions.data?.[0] ?? null);
}

export function usePromptImageUrl(prompt: Prompt | null, type: ImageAssetAlternativeType) {
  const alternativesQuery = useAssetAlternativesQuery(prompt?.type === PromptType.PHOTO ? prompt?.imagesIds[0] : null);
  const alternative = useAlternativeType(alternativesQuery.data, type);

  // When creating the prompt, the smaller assets alternatives will not be available because
  // they are generated asynchronously.
  // In that case, fallback to the original alternative.
  const fallbackAlternative = useAlternativeType(alternativesQuery.data, BaseAssetAlternativeType.ORIGINAL);

  return useAlternativeFileUrl(alternative?.id ?? fallbackAlternative?.id ?? null);
}
